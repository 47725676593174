$(document).ready(function() {

	// Init dotdotdot (string truncate)
	$(".truncate").dotdotdot({
		ellipsis: '...',
		tolerance: 10,
		wrap: 'word'
	});

	// Check dotdotdot when window resized
	$(window).resize(function () {
		$(".truncate").trigger("update");
	});

	/*
     * Init casrousel - slider
     */
	/*$(".carousel").owlCarousel({
		autoPlay: true,
		autoHeight: true,
		autoWidth: true,
		loop: true,
		margin: 10,
		nav: true,
		navText: [
			"<i class='fa fa-5x fa-angle-left'></i>",
			"<i class='fa fa-5x fa-angle-right'></i>"
		],
		stopOnHover: true,
	});*/
	$(".carousel").slick({
		autoplay: true,
		adaptiveHeight: true,
		centerMode: true,
		dots: true,
		infinite: true,
  		variableWidth: true,
		prevArrow: "<div class='slick-nav prev'><i class='fa fa-5x fa-angle-left'></i></div>",
		nextArrow: "<div class='slick-nav next'><i class='fa fa-5x fa-angle-right'></i></div>",
	});

	/*
	 * Parse youtube url
	 */
	$('.swipebox').youtubeParse();

	/*
	 * Change youtube url to youtube image for default image | if not set 
	 */
	$('.youtube-img').youtubeImage();

	/**
	 * Init swipebox - lightbox
	 */
    $('.swipebox').swipebox({
    	
    });

    /*
     * Init Bootstrap tooltip
     */
    $('[data-toggle="tooltip"]').tooltip();

	// This is DOOM?
	doom();

});