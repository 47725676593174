
// ----------------------------------------------------------------------------------------------------
//                                               ADMIN
// ----------------------------------------------------------------------------------------------------

/**
 * Sort to collapse
 * @param  string 		url         routes
 * @param  array 		sorted_list
 */
function sortTabs(url, sorted_list) {
	$.ajax({
		async: false,
		url: url,
		type: 'PUT',
		dataType: 'json',
		data: {sorted_list: sorted_list},
	})
	.done(function(response) {
		setNotify(response.type, response.message);
	});
}

/**
 * Create multi option methods with ajax
 * @param  string 	url 
 * @param  object 	trs 	Get ids from table tr checkbox
 * @return {[type]}     	[description]
 */
function multiOption(url, trs, type) {
	var type = type || 'POST';

	$('.alert').fadeOut("slow", function() {
		$(this).remove();
	});

	var selected_list = {};
	var checkboxes    = {}
	$.each(trs, function(index, val) {
		checkbox = $(this).find('td:first input[type=checkbox][name="multi_check[]"]');
		checkboxes[checkbox.val()] = checkbox;
		if( checkbox.is(':checked') ) {
			selected_list[index] = checkbox.val();
		}
	});

	$.ajax({
		async: false,
		url: url,
		type: type,
		dataType: 'json',
		data: {selected_list: selected_list},
	})
	.done(function(response) {
		if( response.message != undefined ) {
			setAlert(response.type, response.message);
		}
		else {
			$.each(response, function(index, val) {
				if(val.removable != undefined) {
					$.each(val.removable, function(index, val) {
						if(checkboxes[val] != undefined) checkboxes[val].closest('tr').remove();
					});
				}

				setAlert(val.type, val.message);
			});
		}
		// setAlert(response.type, response.message);
	});
}

/**
 * Create alert message
 * @param string 	type    	success, info, warning, danger
 * @param string 	message
 */
function setAlert(type, message) {
	var alert = 	'<div class="alert alert-'+type+'">' +
						'<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
							'<span aria-hidden="true">&times;</span>' +
						'</button>' +
					'<span>'+message+'</span>';

	$('.alert.alert-'+type)
	.fadeOut("slow", function() {
		$(this).remove();
	});

	if( $('.breadcrumb').exists() ) {
		$(alert).insertAfter('.breadcrumb')
				.hide()
				.slideDown("slow");
	}
	else {
		$('.content').prepend(alert)
				.hide()
				.slideDown("slow");
	}
}

/**
 * Create notify message
 * @param {[type]} type     [description]
 * @param {[type]} message  [description]
 * @param {[type]} fade     [description]
 * @param {[type]} position [description]
 */
function setNotify(type, message, fade, position) {
	var fade         = fade || false;
	var position     = position || 'bottom-left';
	var notification = $('<div class="notifications '+position+'"></div>');

	$('.notifications').fadeOut('slow', function() {
		$(this).remove();
	});

	$('body').append(notification);
	notification.notify({
		closable: true,
		fadeOut: { enabled: fade, delay: 10000 },
		message: { html: false, text: message },
		transition: 'fade',
		type: type
	}).show();
	notification.hide().fadeIn('slow');
}

/**
 * Show and hide multi option buttons
 * @param  {Boolean} 	is_checked 	
 * @param  string  		id         	
 * @return {[type]}             	
 */
function toggleMultiButtons(is_checked, id) {
	button = $('.container-fluid.content .multi-option[for="'+id+'"]');

	if(is_checked) 						button.removeClass('hidden');
	else if(!button.hasClass('hidden')) button.addClass('hidden');
}

/**
 * Init cropper
 * @param object _this $(this) => img
 */
function setCropper(el) {
	var id           = el.attr('id'),
		aspect_ratio = eval( el.attr('crop-ratio') ),
	 	positions    = {
			width:  parseFloat( $("div[for="+id+"] input[data-crop=width]").val() ) || 250,
			height: parseFloat( $("div[for="+id+"] input[data-crop=height]").val() ) || 250,
			x:      parseFloat( $("div[for="+id+"] input[data-crop=x]").val() ),
			y:      parseFloat( $("div[for="+id+"] input[data-crop=y]").val() )
		};

   	el.cropper({
   		autoCrop: true,
		aspectRatio: aspect_ratio,
		background: false,
		data: positions,
		guides: false,
		highlight: false,
		viewMode: 1,
		zoomable: false,
		crop: function(e) {
			$("div[for="+id+"] input[data-crop=width]").val( e.width );
			$("div[for="+id+"] input[data-crop=height]").val( e.height );
			$("div[for="+id+"] input[data-crop=x]").val( e.x );
			$("div[for="+id+"] input[data-crop=y]").val( e.y );
		},
	});
}

/**
 * Init sorter
 * @param {[type]} el NOT object, HTML element
 */
function setSortable(el) {
	//Sortable.create(el, {
	el.sortable({
		ghostClass: "ghost",
		animation: 150,
		// handle: "img",
		draggable: ".sortable-item",
		filter: ".checkbox",
		dataIdAttr: "data-id",

		onUpdate: function (/**Event*/evt) {
			sorted_list = {};
			items = $(evt.target).find(this.options.draggable);
			$.each( items, function(index, val) {
				sorted_list[index] = $(this).data('id');
			});

			sortTabs($(evt.target).data('url'), sorted_list);
		},
	});
}

/**
 * Edit with Modal - ajax
 * @param  {[type]} event [description]
 * @return {[type]}       [description]
 */
function ajaxModal(event) {
	event.preventDefault();
	var elem  = $(this);
	var url   = elem.attr('href');
	var title = elem.data('title');
	
	$.ajax({
		url: url,
		type: 'get',
		dataType: 'html',
		data: {},
	}).done(function (response) {
		body   = response;
		footer = '';

		$('#default-modal .modal-title').html(title);
		$('#default-modal .modal-body').html(body);
		$('#default-modal .modal-footer').html(footer);

		$('#default-modal').modal('show');
	});
}

/**
 * Submit form in Modal - ajax
 * @param  {[type]} event [description]
 * @return {[type]}       [description]
 */
function ajaxSubmit(event) {
	event.preventDefault();
	var form   = $(this);
	var url    = form.attr('action');
	var method = form.attr('method');
	var data   = form.find('input, textarea');

	$.ajax({
		url: url,
		type: method,
		dataType: 'json',
		data: data,
	}).done(function(response) {	
		if(response.success) {
			setNotify(response.type, response.message);
			$('#default-modal').modal('hide');
		}
	}).fail(function(response) {
		form.find('.alert').remove();
		lis = '';
		$.each(response.responseJSON, function(index, val) {
			lis += '<li>'+val+'</li>';
		});
		var content = 	'<div class="alert alert-danger">' +
							'<button class="close" aria-label="Close" data-dismiss="alert" type="button">' +
								'<span aria-hidden="true">×</span>' +
							'</button>' +
							'<span>Problémák vannak az űrlap kitöltésével.</span>' +
							'<br>' +
							'<ul>' +
								lis+
							'</ul>' +
						'</div>';
		form.prepend(content);
	});
	
}

// ----------------------------------------------------------------------------------------------------
//                                               FRONTEND
// ----------------------------------------------------------------------------------------------------

var xs_min = 0, 	// mobile min
	xs_max = 767, 	// mobile max
	sm_min = 768, 	// tablet min
	sm_max = 991, 	// tablet max
	md_min = 992, 	// desktop min
	md_max = 1199,  // desktop max
	lg_min = 1200;  // desktop large

/*
 * Init menu: create caret, back btn, sub index
 */
function initMenu() {
	nav_close = '<li class="sm-nav">'+
					'<button class="sm-menu-close" type="button">'+
						'<i class="fa fa-times fa-2x"></i>'+
					'</button>'+
				'</li>';
	nav_back = '<li class="sm-nav">'+
					'<button class="sm-menu-back" type="button">'+
						'<i class="fa fa-angle-right fa-2x"></i>'+
					'</button>'+
				'</li>';

	// Dublicate list element if this have sub elements - You can not hover on mobile
	lis = $('nav.navigation ul li:has(ul)');
	$.each(lis, function(index, li) {
		link = $(li).find('a').first();
		if(link.attr('href') != "#") {
			nav_index  = 	'<li class="sm-nav-index">'+
								'<a href="'+link.href+'">'+
									// link.title+
									$(link).html()+
								'</a>'+
							'</li>';
			// nav_index.addClass('hidden');
			$(li).find('ul').prepend(nav_index);
		}
	});

	lis = $('nav.navigation-v2 ul li:has(ul)');
	$.each(lis, function(index, li) {
		link = $(li).find('a').first();
		if(link.attr('href') != "#") {
			nav_index  = 	'<li class="sm-nav-index">'+
								'<a href="'+link.href+'">'+
									// link.title+
									$(link).html()+
								'</a>'+
							'</li>';
			// nav_index.addClass('hidden');
			$(li).find('ul:first').prepend(nav_index);
		}
	});

	// Create close button to nav
	$('nav.navigation-v2 > ul').prepend(nav_close);

	// Create back button to nav
	$('nav.navigation-v2 ul li:has(ul) ul').prepend(nav_back);

	// Create caret to nav
	$('nav.navigation ul li:has(ul) > a').append('&nbsp;<i class="fa fa-caret-down"></i>');
	$('nav.navigation-v2 ul li:has(ul) > a').append('&nbsp;<i class="fa fa-caret-down"></i>');
}

/*
 * Check menu - show/hide
 */
function checkMenu() {
	if($(window).width() >= sm_max) {
		$('nav.navigation ul').removeAttr('style');

		$('nav.navigation-v2 ul').removeAttr('style');
		$('nav.navigation-v2 ul').css('height', 'auto');
	}
	else {
		uls = $('nav.navigation-v2 ul');
		$.each(uls, function(index, ul) {
			console.log( window.innerHeight, $(ul).innerHeight() );
			if( window.innerHeight > $(ul).innerHeight() ) {
				$(ul).css('height', window.innerHeight+'px');
			}
		});

		/*if( window.innerHeight > $('nav.navigation-v2 ul').innerHeight() ) {
			$('nav.navigation-v2 ul').css('height', window.innerHeight+'px');
		}*/
	}
}

/*
 * Init menu clicks
 */
function initMenuClicks() {
	// navigations (lis a)
	$('body').on('click', 'nav.navigation li a', function(event) {
		if($(window).width() <= sm_max) {
			ul  = $(this).closest('li').find('> ul');
			uls = $(this).closest('li').find('ul');
			if( ul.exists() ) {
				event.preventDefault();
				
				if(ul.css('display') == 'block') {
					uls.slideUp();
				}
				else {
					ul.slideToggle();
				}
			}
		}
	});

	// navigation open
	$('body').on('click', 'nav.navigation .sm-menu', function(event) {
		event.preventDefault();
		ul  = $('nav.navigation > ul');
		uls = $('nav.navigation ul');

		if(ul.css('display') == 'block') {
			uls.slideUp();
		}
		else {
			ul.slideToggle();
		}
	});

	// navigation v2
	$('body').on('click', 'nav.navigation-v2 li a', function(event) {
		if($(window).width() <= sm_max) {
			ul  = $(this).closest('li').find('> ul');
			uls = $(this).closest('li').find('ul');
			if( ul.exists() ) {
				event.preventDefault();
				
				if(ul.css('display') == 'block') {
					uls.hide('slide', { direction: "right" }, 500);
				}
				else {
					ul.show('slide', { direction: "right" }, 500);
				}
			}
		}
	});

	$('body').on('click', 'nav.navigation-v2 .sm-menu', function(event) {
		event.preventDefault();
		ul  = $('nav.navigation-v2 > ul.menubar');
		uls = $('nav.navigation-v2 ul');

		if(ul.css('display') == 'block') {
			uls.hide('slide', { direction: "right" }, 500);
		}
		else {
			ul.show('slide', { direction: "right" }, 500);
		}
	});

	$('body').on('click', 'nav.navigation-v2 .sm-user', function(event) {
		event.preventDefault();
		ul  = $('nav.navigation-v2 > ul.userbar');
		uls = $('nav.navigation-v2 ul');

		if(ul.css('display') == 'block') {
			uls.hide('slide', { direction: "right" }, 500);
		}
		else {
			ul.show('slide', { direction: "right" }, 500);
		}
	});

	// search
	$('body').on('click', 'nav.navigation-v2 .sm-search', function () {
	    $('.search-container').slideToggle();
	});


	// navigation back from lis a
	$('body').on('click', 'nav.navigation-v2 .sm-menu-back', function(event) {
		event.preventDefault();
		ul = $(this).closest('ul');
		ul.hide('slide', { direction: "right" }, 500);
	});

	$('body').on('click', 'nav.navigation-v2 .sm-menu-close', function(event) {
		event.preventDefault();
		ul  = $('nav.navigation-v2 > ul');
		uls = $('nav.navigation-v2 ul');

		uls.hide('slide', { direction: "right" }, 500);
	});
}

/**
 * Checking and setting menu active options
 */
function setActiveMenu() {
	path = window.location.pathname.split( '/' );
	lis  = $('nav ul li');

	$.each(lis, function(index, li) {
		if( 
			$(li).find('a').data('id') != undefined 
			&& path[2] != undefined
			&& $(li).find('a').data('id') == path[2] 
		)
		{
			$(li).addClass('active');
		}
	});
}

/**
 * Write iddqd on page | just a simple easter egg for fun
 * @return {[type]} [description]
 */
function doom() {

	function explode(target) {
		if( $(target).exists() ) {
			$(target).hide('highlight', 500);
		}
	}

	if( window.addEventListener ) {
        var kkeys = [],
	    	words = {
	    		iddqd: {
					text      : "I,D,D,Q,D",
					is_active : false,
					message   : {
						active:   'God mod is activated.',
						deactive: 'God mod is deactivated.',
					},
				},
				idkfa: {
					text      : "I,D,K,F,A",
					is_active : false,
					message   : {
						active:   'No more weapons.',
						deactive: 'No more weapons.',
					},
				},
	    	};

    	words.iddqd.message.active = 
		" _____           _                       _        _                  _   _            _           _   \n"+
		"|  __ \\         | |                     | |      (_)                | | (_)          | |         | |  \n"+
		"| |  \\/ ___   __| |  _ __ ___   ___   __| | ___   _ ___    __ _  ___| |_ ___   ____ _| |_ ___  __| |  \n"+
		"| | __ / _ \\ / _` | | '_ ` _ \\ / _ \\ / _` |/ _ \\ | / __|  / _` |/ __| __| \\ \\ / / _` | __/ _ \\/ _` |  \n"+
		"| |_\\ \\ (_) | (_| | | | | | | | (_) | (_| |  __/ | \\__ \\ | (_| | (__| |_| |\\ V / (_| | ||  __/ (_| |_ \n"+
		" \\____/\\___/ \\__,_| |_| |_| |_|\\___/ \\__,_|\\___| |_|___/  \\__,_|\\___|\\__|_| \\_/ \\__,_|\\__\\___|\\__,_(_)	\n";

		words.iddqd.message.deactive = 
		" _____           _                       _        _           _                 _   _            _           _   \n"+
		"|  __ \\         | |                     | |      (_)         | |               | | (_)          | |         | |  \n"+
		"| |  \\/ ___   __| |  _ __ ___   ___   __| | ___   _ ___    __| | ___  __ _  ___| |_ ___   ____ _| |_ ___  __| |  \n"+
		"| | __ / _ \\ / _` | | '_ ` _ \\ / _ \\ / _` |/ _ \\ | / __|  / _` |/ _ \\/ _` |/ __| __| \\ \\ / / _` | __/ _ \\/ _` |  \n"+
		"| |_\\ \\ (_) | (_| | | | | | | | (_) | (_| |  __/ | \\__ \\ | (_| |  __/ (_| | (__| |_| |\\ V / (_| | ||  __/ (_| |_ \n"+
		" \\____/\\___/ \\__,_| |_| |_| |_|\\___/ \\__,_|\\___| |_|___/  \\__,_|\\___|\\__,_|\\___|\\__|_| \\_/ \\__,_|\\__\\___|\\__,_(_)\n";

		words.idkfa.message.active =
		" _   _                                                                               \n"+
		"| \\ | |                                                                              \n"+
		"|  \\| | ___    _ __ ___   ___  _ __ ___  __      _____  __ _ _ __   ___  _ __  ___   \n"+
		"| . ` |/ _ \\  | '_ ` _ \\ / _ \\| '__/ _ \\ \\ \\ /\\ / / _ \\/ _` | '_ \\ / _ \\| '_ \\/ __|  \n"+
		"| |\\  | (_) | | | | | | | (_) | | |  __/  \\ V  V /  __/ (_| | |_) | (_) | | | \\__ \\_ \n"+
		"\\_| \\_/\\___/  |_| |_| |_|\\___/|_|  \\___|   \\_/\\_/ \\___|\\__,_| .__/ \\___/|_| |_|___(_)\n"+
		"                                                            | |                      \n"+
		"                                                            |_|                       \n";
		words.idkfa.message.deactive = words.idkfa.message.active;

        window.addEventListener("keydown", function(e){
    		var charStr = String.fromCharCode( e.keyCode );
            kkeys.push( charStr );
            $.each(words, function(index, word) {
				if ( kkeys.toString().indexOf( word.text ) >= 0 ) {
	            	words[index].is_active = !words[index].is_active;

	            	console.clear(); 
	            	if( words[index].is_active ) {
	            		console.log(word.message.active);

	            		if(index == 'iddqd') {
		            		$('body').css('cursor', 'crosshair');
		            		$('body').bind('click', function(event) {
		            			event.preventDefault();
		            			explode(event.target);
		            		});
		            	}
	            	}
	            	else {
	            		console.log(word.message.deactive);

	            		if(index == 'iddqd') {
		            		$('body').css('cursor', 'auto');
		            		$('body').unbind('click', explode() );
		            	}
	            	}

	                kkeys = [];
	            }
        	});
        }, true);
	}
}